import { formatMoney } from '@/utils'

export default [
  {
    title: 'ID',
    dataIndex: 'id',
    fixed: 'left',
    width: 100,
  },
  {
    title: 'Активный',
    fixed: 'left',
    width: 110,
    scopedSlots: { customRender: 'is_active' },
  },
  {
    key: 'state',
    title: 'Статус',
    width: 110,
    dataIndex: 'state',
    scopedSlots: { customRender: 'state' },
  },
  {
    title: 'Сумма займа',
    width: 180,
    dataIndex: 'borrow',
    customRender: borrow => formatMoney(borrow),
  },
  {
    title: 'Почта клиента',
    dataIndex: 'client.email',
  },
  {
    title: 'Клиент',
    dataIndex: 'client',
    customRender: client => `${client.first_name} ${client.last_name}`,
  },
  {
    key: 'refId',
    title: 'Ref ID',
    width: 350,
    dataIndex: 'reference_id',
  },
  {
    title: 'Создано',
    dataIndex: 'created_at',
    fixed: 'right',
    width: 180,
    customRender: date => new Date(date).toLocaleString('ru-RU'),
  },
  {
    title: 'Изменено',
    dataIndex: 'updated_at',
    fixed: 'right',
    width: 180,
    customRender: date =>
      date ? new Date(date).toLocaleString('ru-RU') : 'Без изменений',
  },
  {
    title: 'Действия',
    key: 'actions',
    width: 150,
    fixed: 'right',
    scopedSlots: { customRender: 'actions' },
  },
]
