<template>
  <div>
    <a-table
      bordered
      :columns="columns"
      :loading="loading"
      :scroll="{ x: 2000 }"
      :row-key="record => record.id"
      :pagination="{ current: pageNumber, total: requestsTotal }"
      :data-source="requests"
      @change="handleTableChange"
    >
      <div
        slot="is_active"
        slot-scope="record"
        :style="{ display: 'flex', justifyContent: 'center' }"
      >
        <a-popconfirm
          ok-text="Да"
          cancel-text="Нет"
          placement="topRight"
          :title="
            `Вы уверены, что хотите ${
              record.is_active ? 'отключить' : 'включить'
            } заявку?`
          "
          @confirm="updateRequest(record, 'is_active')"
        >
          <a-checkbox v-model="record.is_active"></a-checkbox>
        </a-popconfirm>
      </div>

      <span slot="state" slot-scope="state">
        <a-tag :color="tagColorByState[state]">
          {{ textByState[state] }}
        </a-tag>
      </span>

      <span
        slot="actions"
        slot-scope="record"
        :style="{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }"
      >
        <a-button
          icon="eye"
          type="primary"
          title="Посмотреть"
          @click="
            $router
              .push({
                name: 'requests-edit',
                params: { id: record.id },
              })
              .catch(_err => {})
          "
        />
      </span>
    </a-table>
  </div>
</template>
<script>
import columns from './table_columns'
import { debounce } from '@/utils'
import {
  REQUESTS,
  GET_REQUESTS,
  DELETE_REQUEST,
  REQUESTS_TOTAL,
  UPDATE_REQUEST,
} from '@/store/types'

export default {
  data() {
    return {
      columns,
      loading: false,
      pageNumber: 1,
      textByState: {
        declined: 'Отклонён',
        approved: 'Одобрен',
        processing: 'Ожидает',
      },
      tagColorByState: {
        declined: 'red',
        approved: 'green',
        processing: 'orange',
      },
    }
  },

  computed: {
    requests() {
      return this.$store.getters[REQUESTS]
    },
    requestsTotal() {
      return this.$store.getters[REQUESTS_TOTAL]
    },
  },

  created() {
    this.getRequests()
  },

  methods: {
    getRequests: debounce(function() {
      const { pageNumber } = this

      this.$store.dispatch(GET_REQUESTS, {
        pageNumber,
      })
    }, 300),

    handleTableChange(pagination) {
      this.$router.replace({
        name: 'requests',
        query: { ...this.$route.query, pageNumber: pagination.current },
      })

      this.pageNumber = pagination.current

      this.getRequests()
    },

    updateRequest(request, field) {
      this.$store.dispatch(UPDATE_REQUEST, {
        ...request,
        [field]: !request[field],
      })
    },

    deleteRequest(request) {
      this.$store.dispatch(DELETE_REQUEST, request)
    },
  },
}
</script>
